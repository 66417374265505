@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* モーダル */
.customModal{
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: white !important;
  padding: 20px !important;
  outline: none !important;
  width: 95% !important;
  max-width: 1200px !important;
  height: 95vh !important;
  overflow: auto !important;
  border-radius: 8px !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3) !important;
}

/* モーダルの背景 */
.customOverlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* モーダル内の画像 */
.modalImage{
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
}